import { $ajaxService } from './request'
// import { service } from './request'

/**
 * 获取订单详情 https://paas-teamwork.yuque.com/oundy7/vrp0sx/nh4gsp
 * @param  {} score_order_id 订单id
 */
export const getOrderDetail = ({ score_order_id, score_user_id }) =>
  $ajaxService.get($ajaxService.baseUrl + 'guide/exchange/order_detail', {
    score_order_id,
    score_user_id
  })

/**
 * 核销兑换券 https://paas-teamwork.yuque.com/oundy7/vrp0sx/nh4gsp
 * @param  {} score_order_id 订单id
 */
export const checkQr = ({ score_order_id, score_user_id }) =>
  $ajaxService.post($ajaxService.baseUrl + 'guide/exchange/verification', {
    score_order_id,
    score_user_id
  })

/**
 * 获取订单分享列表 https://paas-teamwork.yuque.com/oundy7/vrp0sx/nh4gsp
 * @param  {} page
 * @param  {} page_size
 * @param  {} customer_userid  用户id
 */
export const getShareOrderList = ({ page, page_size, customer_userid }) =>
  $ajaxService.get($ajaxService.baseUrl + 'guide/share/order_list', {
    page,
    page_size,
    customer_userid
  })

/**
 * 获取商品分享列表 https://paas-teamwork.yuque.com/oundy7/vrp0sx/nh4gsp
 * @param  {} page
 * @param  {} page_size
 * @param  {} goods_name // 商品名称
 */
export const getShareGoodsList = ({ page, page_size, goods_name }) =>
  $ajaxService.get($ajaxService.baseUrl + 'guide/share/score_goods_list', {
    page,
    page_size,
    goods_name
  })

/**
 * 获取轮播图分享列表 https://paas-teamwork.yuque.com/oundy7/vrp0sx/nh4gsp
 * @param  {} page
 * @param  {} page_size
 */
export const getShareActivityList = ({ page, page_size }) =>
  $ajaxService.get($ajaxService.baseUrl + 'guide/share/score_banner_list', {
    page,
    page_size
  })

/**
 * 判断客户是否注册积分商城账号 https://paas-teamwork.yuque.com/oundy7/vrp0sx/oxup40
 * @param  {} customer_userid
 */
export const checkRegistMall = ({ customer_userid }) =>
  $ajaxService.get($ajaxService.baseUrl + 'guide/share/user_exits', {
    customer_userid
  })

// 0.9.3 b2cMall接口
/**
 * 获取商城平台优惠券 https://paas-teamwork.yuque.com/oundy7/vrp0sx/qtivfc
 * @param  {} coupon_name 优惠券名
 */
export const getCouponList = ({ page, page_size, coupon_name }) =>
  $ajaxService.post($ajaxService.baseUrl + 'guide/shop/coupon_list', {
    page,
    page_size,
    coupon_name
  })

/**
 * 获取商城平台商品类目列表 https://paas-teamwork.yuque.com/oundy7/vrp0sx/ev1k0v#KJ6Dg
 */
export const getCategoryList = () =>
  $ajaxService.post($ajaxService.baseUrl + 'shop/category_list', {})

/**
 * 获取商城平台商品列表 https://paas-teamwork.yuque.com/oundy7/vrp0sx/ev1k0v#c65hd
 * @param  {} search_goods_name 商品名
 */
export const getCategoryGoodsList = ({
  goods_category_id,
  search_goods_name
}) =>
  $ajaxService.post($ajaxService.baseUrl + 'shop/get_category_goods_list', {
    goods_category_id,
    search_goods_name
  })

/**
 * 搜索商城平台商品
 * @param  {} search_goods_name 商品名字名
 */
export const getCategoryGoodsSearch = ({ page, limit, search_goods_name }) =>
  $ajaxService.post(
    $ajaxService.baseUrl + 'shop/get_category_goods_list_page',
    {
      page,
      limit,
      search_goods_name
    }
  )

/**
 * 获取订单详情 https://paas-teamwork.yuque.com/oundy7/vrp0sx/nh4gsp
 * @param  {} score_order_id 订单id
 */
export const getExchangeOrderDetail = ({ score_order_id, score_user_id }) =>
  $ajaxService.get($ajaxService.baseUrl + 'guide/shop/exchange_order_detail', {
    score_order_id,
    score_user_id
  })

/**
 * 核销兑换券 https://paas-teamwork.yuque.com/oundy7/vrp0sx/nh4gsp
 * @param  {} score_order_id 订单id
 */
export const exchangeCheckQr = ({ score_order_id, score_user_id }) =>
  $ajaxService.post($ajaxService.baseUrl + 'guide/shop/exchange_verification', {
    score_order_id,
    score_user_id
  })

/**
 * 获取优惠券发送封面图接口 https://paas-teamwork.yuque.com/oundy7/vrp0sx/qtivfc#txBsH
 * @param  {} coupon_id 优惠券id
 */
export const couponShareImg = ({ coupon_id }) =>
  $ajaxService.post($ajaxService.baseUrl + 'oss/shop/coupon_share_img', {
    coupon_id
  })
