<template>
  <div class="knowledge-search-page">
    <form  @submit.prevent action="#">
      <van-search
        v-model="goodsParams.search_goods_name"
        show-action
        placeholder="搜索"
        @search="onSearch"
        @cancel="onCancel"
      />
    </form>
    <div class="search-content">
      <van-list
        v-if="goodsData.length"
        v-model:loading="form.loading"
        v-model:error="form.error"
        :finished="form.finished"
        :immediate-check="false"
        error-text="请求失败，点击重新加载"
        :finished-text="goodsData.length == 0 ? '暂无数据' : '没有更多了'"
        @load="onload"
      >
        <div class="p15" v-for="item in goodsData" :key="item.coupon_id">
          <div class="flex pl4 pt12 pb12 bgf">
            <img
              :src="item.order_list_image"
              alt=""
              class="img-class flex-s0 flex-g0"
            />
            <div class="ml12 flex-s1 flex-g1">
              <div class="fs14 cl17 fw400 txt-line-1">
                {{ item.goods_title }}
              </div>
              <div class="flex flex-justify-between">
                <div>
                  <div class="fs12 fw400 cl99">
                    可售：{{ item.inventory_total }}
                  </div>
                  <div class="mt4">
                    <span class="cl34">￥</span>
                    <span class="fs20 fw500 cl34">
                      {{ item.sales_price }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-g0 flex-s0 mt18">
              <van-button
                round
                type="primary"
                size="small"
                :disabled="item.inventory_total == 0"
                @click="handleShare(item)"
              >
                发送
              </van-button>
            </div>
          </div>
          <div class="bdb"></div>
        </div>
      </van-list>
      <div v-if="goodsData.length === 0 && form.searched" class="search-nodata">
        暂无搜索结果
      </div>
    </div>
  </div>
</template>

<script setup>
import { getCategoryGoodsSearch } from '@/service/checkQr.service'
import { ref, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { sendChatMessageAllType } from '@/utils/nwechat.js'
import { formatParamsDistribution } from '@/utils/index.js'
import {
  sendMonitor,
  recordCustomerDynamic
} from '@/service/quickSend.service.js'
import { Toast } from 'vant'
const router = useRouter()
const route = useRoute()

const goodsParams = reactive({
  page: 1,
  limit: 10,
  search_goods_name: ''
})
const goodsData = ref([])
const form = reactive({
  loading: false,
  error: false,
  finished: false,
  searched: false
})

const share_data_id = ref('')

// const reactDate = ref({
//   customer_id: '',
//   chart_id: '',
// })

const onSearch = () => {
  goodsData.value = []
  goodsParams.page = 1

  if (!goodsParams.search_goods_name) {
    return
  }
  form.searched = true
  onload()
}

const onload = async () => {
  if (goodsData.value.length > 0) {
    goodsParams.page += 1
  }
  try {
    form.loading = true
    let { data, code } = await getCategoryGoodsSearch(goodsParams)
    console.log('data', data)

    if (code !== 200) {
      form.error = true
      throw code
    }

    goodsData.value = goodsData.value.concat(data)
    form.loading = false

    // 翻到最后一页
    if (
      goodsData.value.length < goodsParams.limit ||
      data.length < goodsParams.limit
    ) {
      form.finished = true
    }
  } catch (error) {
    form.error = true
    form.finished = true
    console.log(error)
  } finally {
    form.loading = false
    return Promise.resolve(true)
  }
}

// 发送前的埋点回调
const beforeSend = async (goods_id) => {
  let { data, code } = await sendMonitor({
    payable_id: goods_id,
    type: 'goods',
    user_id: route.query.customer_id || ''
  })
  if (code == 200) {
    share_data_id.value = data.share_data_id
  }
  return Promise.resolve(true)
}

const handleShare = async (item) => {
  Toast.loading({
    message: '加载中',
    forbidClick: true,
    duration: 0
  })
  if (route.query.customer_id) {
    await beforeSend(item.goods_id)
  }
  try {
    let title = item.xcx_share_title
    let imgUrl = item.xcx_share_image
    let page = `pages/goodsDetail/index.html?goods_id=${item.goods_id}&is_share=1&member_id=${route.query.userId}`
    page = await formatParamsDistribution(
      page,
      route.query.customer_id,
      route.query.chart_id,
      'b2c_goods'
    )
    // 如果存在埋点id加入埋点id
    if (share_data_id.value)
      page = `${page}&share_data_id=${share_data_id.value}`

    let shareObj = {
      appid: 'wx754859a6c762e762', //小程序的appid 群店:wxbecd55f783f6a0cd; aosifen: wx754859a6c762e762
      title,
      imgUrl, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
      page //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
    }
    // console.log('发送前参数', shareObj)
    const { sendStatus } = await sendChatMessageAllType('miniprogram', {
      miniprogram: { ...shareObj }
    })

    // 如果发送成功， 则调用记录动态接口
    if (sendStatus) recordDynamic(item.goods_id)
    Toast.clear()
  } catch (e) {
    console.log('报错', e)
    Toast.clear()
  }
}

// 记录客户动态
function recordDynamic(id) {
  recordCustomerDynamic({
    event_key: route.query.customer_id
      ? 'member_share_goods_customer'
      : 'member_share_goods_groupchat',
    field: route.query.customer_id ? 'external_contact' : 'qw_chat_id',
    field_value: route.query.customer_id || route.query.chart_id,
    relate_id: id,
    relate_class: 'shop_goods'
  })
}

// 取消
const onCancel = () => {
  router.go(-1)
}
</script>

<style lang="less" scoped>
.knowledge-search-page {
  .search-content {
    box-sizing: border-box;
    // width: 295px;
    background-color: #f5f5f5;
    height: calc(100vh - 55px);
    padding: 20px 15px;
    overflow-y: scroll;
    position: relative;
  }

  .search-nodata {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    margin: auto;
    color: #909399;
    text-align: center;
    font-size: 14px;
  }
}
.img-class {
  width: 66px;
  height: 66px;
}
:deep(.van-button--small) {
  width: 56px !important;
  font-size: 14px !important;
}
</style>
